























import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { useAuth } from "@/api_connectors/auth";
import router from "@/router";
import { WELCOME_REGISTER_KEY } from "@/assets/globalVariables";

export default defineComponent({
    name: "VerifyEmail",
    setup() {
        const { confirmEmail } = useAuth()

        const errorVerify = ref(false)
        const requestConfirm = ref(false)

        const update = async (): Promise<void> => {
            // console.log(router.currentRoute.params.token)
            const token = router.currentRoute.params.token
            if (token) {
                const response = await confirmEmail(token)
                // console.log(response)
                if (response) {
                    // setTimeout(() => router.push('/'), 2000)
                    requestConfirm.value = true
                    return;
                }
                errorVerify.value = true;
            }
        }

        const routeToFlow = (): void => {
            if (localStorage.getItem(WELCOME_REGISTER_KEY) === 'true') {
                localStorage.removeItem(WELCOME_REGISTER_KEY)
                router.push({ name: 'WelcomeVoucher' })
                return
            }
            router.push('/').catch(() => {
            })
        }

        onMounted(() => {
            update()
        })

        return {
            errorVerify,
            requestConfirm,
            routeToFlow
        }
    }
})
